<template>
  <router-view />
</template>

<script type="text/javascript">

export default {
  name: 'UserIndex'
}

</script>
